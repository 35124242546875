
import {defineComponent, ref} from "vue";

export default defineComponent({

    setup(props, {emit}) {

        let refComp_Container = ref(null);
        let refComp_InpEmail = ref(null);

        function showComp(prmDefaulEmail) {
            console.log("showComp");
            (refComp_Container.value as HTMLElement).style.display = "";
            (refComp_InpEmail.value as HTMLInputElement).value = prmDefaulEmail;
        }

        function click_Send() {

            let vvEmail = (refComp_InpEmail.value as HTMLInputElement).value;

            if (vvEmail!=="") {
                emit("onClickSend", vvEmail );
                (refComp_Container.value as HTMLElement).style.display = "none";
            }
        }

        return {
            showComp,
            refComp_Container, refComp_InpEmail,
            click_Send,
        };
    },
});

